import React from 'react'
import { useAuthors } from '../../hooks/useAuthors'
import { Avatar } from './Avatar'

export const AvatarList = ({ authors: authorIds }) => {
  const allAuthors = useAuthors()
  const authorList = authorIds.map((id) => allAuthors[id])

  return authorList.map((author) => <Avatar key={author.id} author={author} />)
}
