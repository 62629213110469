import React from 'react'
import { Link, graphql } from 'gatsby'
import { CalendarBlank, Coffee } from 'phosphor-react'
import { BasicLayout } from '../layouts/basic'
import { AuthorList } from '../components/blog/AuthorList'
import { AvatarList } from '../components/blog/AvatarList'
import { SEOMeta } from '../components/SEOMeta'

const BORDER_RADIUS = 8

const Blog = ({
  location,
  data: {
    allMdx: { nodes: posts },
  },
}) => {
  return (
    <BasicLayout>
      <SEOMeta title="Blog" path={location.pathname} />
      <div className="mx-auto max-w-4xl">
        <main className="mx-auto p-6 pb-12 max-w-6xl text-text-primary-light text-base leading-relaxed">
          <header className="md:grid md:grid-flow-col md:grid-cols-4 md:my-16">
            <h1 className="text-text-primary-light text-5xl font-extrabold tracking-tight leading-tight md:col-span-3 md:col-start-2">
              Blog
            </h1>
          </header>
          <section className="mt-6 space-y-6">
            {posts.map((post) => (
              <article
                key={post.id}
                className="py-6 md:grid md:grid-flow-col md:grid-cols-4"
              >
                <div className="md:col-start-1 md:row-start-1">
                  <AvatarList authors={post.frontmatter.authors} />
                  <div className="mt-4 text-text-primary-light text-sm sr-only md:hidden">
                    <AuthorList authors={post.frontmatter.authors} />
                  </div>
                  <div className="hidden md:flex md:items-center md:mt-4 md:text-text-secondary-light md:text-sm md:space-x-1">
                    <CalendarBlank className="flex-shrink-0" />
                    <time>{post.frontmatter.date}</time>
                  </div>
                </div>
                <div className="md:col-span-3 md:col-start-2">
                  <h1 className="mt-4 text-text-primary-light text-3xl font-extrabold tracking-tight leading-tight md:mt-0">
                    <Link to={postPath(post)}>{post.frontmatter.title}</Link>
                  </h1>
                  <div className="flex items-center mt-4 text-text-secondary-light text-sm space-x-1 md:hidden">
                    <CalendarBlank className="flex-shrink-0" />
                    <time>{post.frontmatter.date}</time>
                  </div>
                  <p className="mt-6 text-lg">{post.excerpt}</p>
                </div>
                <footer className="row-start-6 md:col-span-3 md:col-start-2">
                  <Link
                    className="inline-block mt-6 text-text-primary-light hover:text-white text-sm bg-gradient-to-br from-blue-700 to-teal-400"
                    style={{ borderRadius: BORDER_RADIUS }}
                    to={postPath(post)}
                  >
                    <span
                      className="flex items-center px-4 py-2.5 bg-background-four-light hover:bg-opacity-0 border border-border-normal-light hover:border-transparent space-x-2 transition-all duration-200"
                      style={{ borderRadius: BORDER_RADIUS - 1 }}
                    >
                      <span>Keep reading</span>
                      <Coffee
                        className="relative left-0.5 flex-shrink-0"
                        size={20}
                        weight="light"
                      />
                    </span>
                  </Link>
                </footer>
              </article>
            ))}
          </section>
        </main>
      </div>
    </BasicLayout>
  )
}

const postPath = (post) => `/${post.fields.source}${post.fields.slug}`

export const query = graphql`
  query {
    allMdx(
      filter: {
        frontmatter: { published: { eq: true } }
        fields: { source: { eq: "blog" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        excerpt
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          authors
        }
        fields {
          source
          slug
        }
      }
    }
  }
`

export default Blog
