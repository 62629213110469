import React from 'react'
import { useAuthors } from '../../hooks/useAuthors'

export const AuthorList = ({ authors: authorIds }) => {
  const allAuthors = useAuthors()
  const authorList = joinAuthors(authorIds.map((id) => allAuthors[id]))

  return <>By {authorList}</>
}

const joinAuthors = (authors) => {
  const lastAuthor = authors.pop()
  if (authors.length > 0) {
    return `${authors.map((a) => a.name).join(', ')} and ${lastAuthor.name}`
  } else if (!!lastAuthor) {
    return lastAuthor.name
  }
}
